<template>
    <ComboboxContent v-bind="forwarded" :class="contentClass">
        <div role="presentation">
            <slot />
        </div>
    </ComboboxContent>
</template>
<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import type { ComboboxContentEmits, ComboboxContentProps } from "reka-ui";
import { ComboboxContent, useForwardPropsEmits } from "reka-ui";

const props = withDefaults(defineProps<ComboboxContentProps & { class?: HTMLAttributes["class"] }>(), {
    dismissable: false,
    class: "",
});
const emits = defineEmits<ComboboxContentEmits>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

const contentClass = computed(() => cn("max-h-40 md:max-h-80 overflow-y-auto overflow-x-hidden", props.class));
</script>
