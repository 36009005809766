<template>
    <ComboboxRoot
        v-bind="forwarded"
        :class="cn('flex h-full w-full flex-col overflow-hidden bg-white text-gray-950', props.class)">
        <slot />
    </ComboboxRoot>
</template>
<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import type { ComboboxRootEmits, ComboboxRootProps } from "reka-ui";
import { ComboboxRoot, useForwardPropsEmits } from "reka-ui";

const props = withDefaults(defineProps<ComboboxRootProps & { class?: HTMLAttributes["class"] }>(), {
    open: true,
    modelValue: "",
    class: "",
});

const emits = defineEmits<ComboboxRootEmits>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>
